<template>
  <section class="tables">
    
    <div class="page-header">
      <h3 class="page-title">Shipping Services</h3>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-32">
                <select
                  class="custom-select form-control"
                  @change="sortByType"
                  v-model="type"
                >
                  <option value="" selected>Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Method</th>
                  <th>Enabled</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="shipping in shipping" :key="shipping.id">
                  <td>
                    <code>{{ shipping.name }}</code>
                  </td>

                  <td>
                    <toggle-button
                     @change="onToggleChange(shipping.id, $event)"
                      v-model="shipping.active"
                      :labels="{ checked: 'true', unchecked: 'false' }"
                    />
                  </td>
                  <td>{{ shipping.description }}</td>
                  <td>
                    <router-link
                      class="badge badge-info"
                      :to="`/settings/edit-shipping-services/${shipping.id}`"
                    >
                      <span v-if="shipping.active == 1">Manage</span>
                      <span v-else>Set up</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 pt-3 text-center" v-if="shipping == ''">
              No data found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";

export default {
  name: "shipping-list",
  edit: {
      active: Boolean,
      name: String,
      description: String,
      image_id: Number,
    },
  components: {
    
  },
  data() {
    return {
      shipping: this.edit,
      
      type: "",
      body: ""
    };
  },
  mounted() {
    this.shippingServices();
  },
  methods: {
    async shippingServices() {
      
      const { data } = await setingsApi.shipping();
      this.shipping = data.data.data;
      this.shipping.forEach((val) => {
        if (val.active == 1) {
          val.active = true;
        } else {
          val.active = false;
        }
      });

      
    },
    async sortByType() {
      if (this.type === "") {
        this.shippingServices();
      } else {
        
        const { data } = await setingsApi.sortByShippingStatus(this.type);
        this.shipping = data.data.data;
        
      }
    },
    async onToggleChange(id, event) {

      this.body = { active: event.value, };
      
      const data = await setingsApi.updateShippingService(id, {
        ...this.body,
      });
      
      this.shippingServices();
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped></style>
